declare global {
  interface Window {
    env: { [key: string]: string };
  }
}
const env = process.env.NODE_ENV === "production" ? window.env : process.env;

function getEnv(envVar: string): string {
  const envValue = env[envVar];

  if (typeof envValue !== "string") {
    throw new Error(`Missing env var "${envVar}".`);
  }

  return envValue;
}

export const dropitoAPIUrl = getEnv("VUE_APP_DROPITO_API_URL");
export const authAPIUrl = getEnv("VUE_APP_AUTH_API_URL");
