
import {
  EqualsStepParameters,
  RichValueType,
  StepParameters,
} from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["parameters"],
})
export default class RichValue extends Vue {
  parameters!: StepParameters;
  valueType: RichValueType | null = null;
  isComponentSelectorActive = false;

  setBooleanValue(value: boolean): void {
    this.parameters.value = value;
  }

  mounted(): void {
    this.defineStepValue();
  }

  defineStepValue(): void {
    if (this.parameters.value === undefined || this.parameters.value === "") {
      return;
    }

    if (
      this.parameters.value === "true" ||
      this.parameters.value === "false" ||
      typeof this.parameters.value === "boolean"
    ) {
      this.valueType = EqualsStepParameters.RADIO;
      return;
    }

    if (typeof this.parameters.value === "string") {
      this.valueType = EqualsStepParameters.TEXT;
      return;
    }

    if (!Number.isNaN(this.parameters.value)) {
      this.valueType = EqualsStepParameters.NUMBER;
      return;
    }
  }

  stepValues(): typeof EqualsStepParameters {
    return EqualsStepParameters;
  }

  get dropdownTitle(): string {
    return !this.valueType
      ? "Choisir le type de la donnée :"
      : this.valueType.value;
  }
}
