import { Page, Scenario, Selector } from "@/model/Scenario";

export function createEmptyScenario(): Scenario {
  return {
    id: "sample",
    name: "Sample",
    firstPageId: "",
    pages: [],
  };
}

export function createEmptyPage(id: string): Page {
  return {
    id: id,
    label: id,
    displayedData: [],
    nextPagesIds: [],
    identifiers: [],
    conditions: [],
    blocks: [],
  };
}

export function createDefaultSelector(): Selector {
  return {
    type: "XPathSelector",
    path: "",
  };
}
