
import { Options, Vue } from "vue-class-component";
import { Page, Scenario } from "@/model/Scenario";
import { createEmptyPage } from "@/service/ScenarioFactory";
import PageEditor from "@/components/PageEditor.vue";
import ScenarioEditorHeaderButtons from "@/components/ScenarioEditorHeaderButtons.vue";

@Options({
  props: ["scenario"],
  components: {
    ScenarioEditorHeaderButtons,
    PageEditor,
  },
})
export default class ScenarioEditor extends Vue {
  scenario!: Scenario;
  activePageId = "first-page";
  newPageNumber = 0;

  isActivePage(id: string): boolean {
    return id == this.activePageId;
  }

  setActivePage(id: string): void {
    this.activePageId = id;
    scroll(0, 0);
  }

  removePage(deletedPageId: string): void {
    this.$store.commit("deleteNextPageId", {
      scenarioId: this.scenario.id,
      pageIdToDelete: deletedPageId,
    });
    this.scenario.pages = this.scenario.pages.filter(
      (page) => page.id !== deletedPageId
    );
  }

  createPage(): void {
    const newPageId = `new-page${this.newPageNumber}`;
    this.scenario.pages.push(createEmptyPage(newPageId));
    this.newPageNumber++;
    this.setActivePage(newPageId);
  }

  getActivePage(): Page {
    return (
      this.scenario.pages.find((page) => page.id === this.activePageId) ||
      createEmptyPage("")
    );
  }

  changePageId(pageId: string): void {
    this.setActivePage(pageId);
  }
}
