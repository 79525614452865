import axios, { AxiosError } from "axios";
import { Scenario } from "@/model/Scenario";
import { DropitoAPIError } from "@/service/dropito-api/DropitoAPIError";
import { dropitoAPIUrl } from "@/environement";
import { Task, UserData, Violation } from "@/model/Task";
import { DataSchema } from "@/model/DataSchema";
import { isNil } from "lodash";
import { AuthService } from "@/service/auth/AuthService";

export class DropitoAPIService {
  public constructor(private readonly authService: AuthService) {}

  public async getAllScenarios(): Promise<Scenario[]> {
    const token = await this.authService.getToken();
    try {
      const response = await axios.get(`${dropitoAPIUrl}/v1/scenarios`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (e) {
      throw new DropitoAPIError(DropitoAPIService.parseError(e));
    }
  }

  public async updateScenario(scenario: Scenario): Promise<void> {
    const token = await this.authService.getToken();
    try {
      await axios.patch(`${dropitoAPIUrl}/v1/scenarios`, scenario, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      throw new DropitoAPIError(DropitoAPIService.parseError(e));
    }
  }

  public async createScenario(scenario: Scenario): Promise<void> {
    const token = await this.authService.getToken();
    try {
      await axios.post(`${dropitoAPIUrl}/v1/scenarios`, scenario, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      throw new DropitoAPIError(DropitoAPIService.parseError(e));
    }
  }

  public async createTask(
    scenarioId: string,
    userData: UserData
  ): Promise<Task> {
    const token = await this.authService.getToken();
    return axios
      .post(
        `${dropitoAPIUrl}/v1/tasks`,
        {
          scenarioId,
          applicationId: "dropitoScenarioEditor",
          clientId: "test-client",
          userData: userData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return response.data as Task;
      })
      .catch((e) => {
        if (e.response.status == 400 && !isNil(e.response.data.violations)) {
          let errorMsg = "";
          const violations = e.response.data.violations as Violation[];
          violations.forEach(
            (violation) =>
              (errorMsg += `<strong>${violation.property}</strong>:${violation.message}<br/>`)
          );
          throw new DropitoAPIError(errorMsg);
        } else {
          throw new DropitoAPIError(DropitoAPIService.parseError(e));
        }
      });
  }

  public async getUsedData(scenarioId: string): Promise<string[]> {
    const token = await this.authService.getToken();
    return axios
      .get(`${dropitoAPIUrl}/v1/used-data/scenario/${scenarioId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((e) => {
        throw new DropitoAPIError(DropitoAPIService.parseError(e));
      });
  }

  public async getDataSchema(): Promise<DataSchema> {
    const token = await this.authService.getToken();
    return axios
      .get(`${dropitoAPIUrl}/v1/data-schema`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((e) => {
        throw new DropitoAPIError(DropitoAPIService.parseError(e));
      });
  }

  private static parseError(e: AxiosError): string {
    return (
      "Erreur du serveur : " + e.response?.status + " " + e.response?.statusText
    );
  }
}
