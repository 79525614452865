
import { StepParameters } from "@/model/Scenario";
import { createDefaultSelector } from "@/service/ScenarioFactory";
import { Options, Vue } from "vue-class-component";
import SelectorForm from "@/components/form/SelectorForm.vue";

@Options({
  props: ["parameters"],
  components: { SelectorForm },
})
export default class TypeIBANPart extends Vue {
  parameters!: StepParameters;

  beforeCreate(): void {
    this.parameters.selector ??= createDefaultSelector();
    this.parameters.ibanPart ??= 0;
    this.parameters.text ??= "";
    this.parameters.description ??= "";
  }
}
