
import { Options, Vue } from "vue-class-component";
import { Scenario } from "@/model/Scenario";
import { createGraph } from "@/graphHelper/createGraph";

@Options({
  props: ["scenario"],
  watch: {
    scenario: [{ handler: "buildGraph", immediate: true, deep: true }],
  },
})
export default class ScenarioGraph extends Vue {
  scenario!: Scenario;

  mounted(): void {
    this.buildGraph();
  }

  buildGraph(): void {
    createGraph("scenario-graph", this.scenario);
  }
}
