
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["isSaving"],
})
export default class Modal extends Vue {
  isSaving = false;

  closeModal(): void {
    this.$emit("closed");
  }

  confirmModal(): void {
    this.$emit("confirmed");
  }

  mounted(): void {
    document
      .querySelectorAll(
        ".modal-background, .modal-card-head .delete, .modal-card-foot .button.cancel"
      )
      .forEach((close_element_modal) => {
        close_element_modal.addEventListener("click", () => {
          this.closeModal();
        });
      });
  }
}
