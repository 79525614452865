
import { Options, Vue } from "vue-class-component";
import { Page } from "@/model/Scenario";

@Options({
  props: ["page", "scenarioId"],
})
export default class PageEditorHeader extends Vue {
  page!: Page;
  scenarioId!: string;
  onEditingPageId = false;
  newPageId = "";
  errorNewPageId = "";

  editPageId(): void {
    this.onEditingPageId = true;
    this.newPageId = this.page.id;
  }

  savePageId(): void {
    this.errorNewPageId = "";
    if (this.newPageId === "") {
      this.errorNewPageId = "Le page id ne peut pas être vide";
      return;
    }
    if (this.newPageId === this.page.id) {
      this.resetEditingPageId();
      return;
    }
    if (
      this.$store.getters.isPageIdAlreadyExist(this.newPageId, this.scenarioId)
    ) {
      this.errorNewPageId =
        "Un même page id existe déjà. Merci d'en choisir un autre";
      return;
    }
    this.$store.commit("changeNextPageId", {
      scenarioId: this.scenarioId,
      oldNextPageId: this.page.id,
      newNextPageId: this.newPageId,
    });
    this.page.id = this.newPageId;
    this.$parent?.$emit("changePageId", this.page.id);
    this.resetEditingPageId();
  }

  resetEditingPageId(): void {
    this.onEditingPageId = false;
    this.errorNewPageId = "";
    this.newPageId = "";
  }
}
