
import { Options, Vue } from "vue-class-component";
import { Scenario } from "@/model/Scenario";
import PageEditor from "@/components/PageEditor.vue";
import Modal from "@/components/shared/Modal.vue";
import CodeEditor from "@/components/shared/CodeEditor.vue";
import { DropitoAPIService } from "@/service/dropito-api/DropitoAPIService";
import { UserData } from "@/model/Task";
import { UserDataService } from "@/service/UserDataService";

@Options({
  inject: {
    dropitoAPIService: "DropitoAPIService",
    userDataService: "UserDataService",
  },
  props: ["scenario"],
  components: {
    PageEditor,
    CodeEditor,
    Modal,
  },
})
export default class ScenarioEditorHeaderButtons extends Vue {
  scenario!: Scenario;
  isSaving = false;
  isUploading = false;
  isModalVisible = false;
  isCreatingTask = false;
  errorMessage = "";
  modalErrorMessage = "";
  onSuccessMessage = "";
  testScenarioUserData: UserData = {};
  dropitoAPIService!: DropitoAPIService;
  userDataService!: UserDataService;

  $refs!: {
    inputFileScenario: HTMLInputElement;
  };

  async mounted(): Promise<void> {
    this.testScenarioUserData = await this.userDataService.createUserData(
      this.scenario.id
    );
  }

  async saveScenario(): Promise<void> {
    this.resetNotificationMessages();
    this.isSaving = true;
    try {
      await this.dropitoAPIService.updateScenario(this.scenario);
      this.displaySuccessMessage("Le scénario a été enregistré !");
    } catch (e) {
      this.errorMessage = e.message;
    } finally {
      this.isSaving = false;
    }
  }

  get hrefDownload(): string {
    return (
      "data:text/plain;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.scenario, null, 2))
    );
  }

  async upload(): Promise<void> {
    try {
      this.resetNotificationMessages();
      this.isUploading = true;
      const inputFile = this.$refs.inputFileScenario;
      if (!inputFile.files) {
        return;
      }
      const newScenario: Scenario = await JSON.parse(
        await inputFile.files[0].text()
      );
      this.$store.commit("overwriteExistingScenario", newScenario);
      this.displaySuccessMessage("Le scénario a été importé !");
    } catch (e) {
      this.errorMessage = e;
    } finally {
      this.isUploading = false;
    }
  }

  async closeTestScenarioModal(): Promise<void> {
    this.isModalVisible = false;
    this.modalErrorMessage = "";
  }

  async openTestScenarioModal(): Promise<void> {
    this.testScenarioUserData = await this.userDataService.createUserData(
      this.scenario.id
    );
    this.isModalVisible = true;
  }

  async testScenario(): Promise<void> {
    await this.dropitoAPIService
      .createTask(this.scenario.id, this.testScenarioUserData)
      .then((task) => {
        window.open(task.deeplink);
      })
      .catch((e) => {
        this.modalErrorMessage = e;
      });
  }

  resetNotificationMessages(): void {
    this.errorMessage = "";
    this.onSuccessMessage = "";
  }

  displaySuccessMessage(message: string): void {
    this.onSuccessMessage = message;
    setTimeout(() => {
      this.onSuccessMessage = "";
    }, 1500);
  }

  updateUserData(code: string): void {
    try {
      this.testScenarioUserData = JSON.parse(code);
    } catch (e) {
      this.modalErrorMessage = e;
    }
  }

  get hasSuccessMessage(): boolean {
    return this.onSuccessMessage !== "";
  }

  get hasAPIError(): boolean {
    return this.errorMessage !== "";
  }
}
