import {
  MalformedJWTAuthError,
  NoTokenAuthError,
  RefreshTokenAuthError,
} from "@/service/auth/AuthError";
import axios from "axios";
import { authAPIUrl } from "@/environement";
import { isNil } from "lodash";
import { AuthService } from "@/service/auth/AuthService";

const DROPITO_APP_ID = "fibii";

const REQUIRED_ROLES = ["ROLE_DROPITO", "ROLE_EMPLOYEE_IT"];

export interface JWTContent {
  "app-id": string;
  "user-id": string;
  roles: string[];
  exp: number;
  type: string;
  iat: number;
}

export interface JWTAuth {
  token: string;
  refreshToken: string;
  parsed: JWTContent;
}

export class JWTTokenAuthService implements AuthService {
  private jwt?: JWTAuth;

  public isUserAuthenticated(): boolean {
    return this.jwt !== undefined;
  }

  public async getToken(): Promise<string> {
    if (isNil(this.jwt)) {
      throw new NoTokenAuthError();
    }
    if (JWTTokenAuthService.isJWTExpired(this.jwt)) {
      this.jwt = await this.refreshJWTToken(this.jwt);
    }
    return this.jwt.token;
  }

  public static isJWTExpired(jwt: JWTAuth): boolean {
    const jwtBody = JWTTokenAuthService.decodeJWT(jwt.token);
    const expiresAtMs = (jwtBody.exp - 5 * 60) * 1000; // Nouveau token 5mn avant la date d'expiration
    return expiresAtMs < Date.now();
  }

  public static decodeJWT(token: string): JWTContent {
    try {
      return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
    } catch (e) {
      throw new MalformedJWTAuthError(e.message);
    }
  }

  private async refreshJWTToken(jwt: JWTAuth): Promise<JWTAuth> {
    try {
      const response = await axios.post(
        `${authAPIUrl}/v1/jwt/refresh`,
        {
          appId: DROPITO_APP_ID,
          token: jwt.token,
          refreshToken: jwt.refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
        parsed: JWTTokenAuthService.decodeJWT(response.data.token),
      };
    } catch (e) {
      const previousToken = jwt.refreshToken;
      const reason = e.response
        ? JSON.stringify(e.response.data)
        : "Unknown Error";

      throw new RefreshTokenAuthError(previousToken, reason);
    }
  }

  public async authLogin(credentials: {
    username: string;
    password: string;
  }): Promise<void> {
    try {
      const response = await axios.post(`${authAPIUrl}/auth`, credentials, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const newJWT = {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
        parsed: JWTTokenAuthService.decodeJWT(response.data.token),
      };

      for (const role of REQUIRED_ROLES) {
        if (!newJWT.parsed.roles.includes(role)) {
          throw new Error(
            `Only users with roles [${REQUIRED_ROLES.join(
              ", "
            )}] can access this page`
          );
        }
      }
      this.jwt = newJWT;
      return;
    } catch (e) {
      if (typeof e.toJSON !== "function") {
        throw e;
      }

      const error: { config?: { data: string } } = e.toJSON();

      if (error.config?.data) {
        error.config.data = "**** hidden ****";
      }

      throw new Error(e.response?.data?.message ?? "An error occurred");
    }
  }
}
