import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prism_editor = _resolveComponent("prism-editor")!

  return (_openBlock(), _createBlock(_component_prism_editor, {
    modelValue: _ctx.code,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
    class: "code-editor",
    highlight: _ctx.highlighter,
    "line-numbers": "",
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.notifyChange()))
  }, null, 8, ["modelValue", "highlight"]))
}