import { Scenario } from "@/model/Scenario";
import * as d3 from "d3";
import { ForceLink, SimulationLinkDatum, SimulationNodeDatum } from "d3";

/**
 * Represents a node in the graph
 */
class PageNode implements SimulationNodeDatum {
  public id: number;
  public name: string;
  public highlight: boolean;
  public x: number;
  public y: number;
  public fx?: number | null;
  public fy?: number | null;

  constructor(id: number, name: string, highlight: boolean) {
    this.name = name;
    this.id = id;
    this.highlight = highlight;
    this.x = 0;
    this.y = 0;
  }
}

/**
 * Represents a link between two nodes in the graph
 */
class PageLink implements SimulationLinkDatum<PageNode> {
  constructor(public source: PageNode, public target: PageNode) {}
}

/**
 * Represents the dataSet inputed to the D3 Graph
 */
type GraphDataSet = {
  nodes: PageNode[];
  links: PageLink[];
};

/**
 * Creates a D3 dataset from the scenario
 * @param scenario
 * @returns
 */
function buildDataset(scenario: Scenario): GraphDataSet {
  const links: PageLink[] = [];

  let nodeId = 0;
  const nodes = scenario.pages.map((page) => {
    nodeId++;
    const shouldHighlight =
      page.id == scenario.firstPageId || page.nextPagesIds.length === 0;
    return new PageNode(nodeId, page.id, shouldHighlight);
  });

  for (const page of scenario.pages) {
    const source = nodes.find((node) => node.name == page.id) as PageNode;
    for (const nextPageId of page.nextPagesIds) {
      const target = nodes.find((node) => node.name == nextPageId) as PageNode;
      links.push(new PageLink(source, target));
    }
  }

  return {
    nodes,
    links,
  };
}

/**
 * Force-Directed Graph generation using D3.js
 * Inspired from https://observablehq.com/@xianwu/force-directed-graph-network-graph-with-arrowheads-and-lab
 */
export function createGraph(htmlElementId: string, scenario: Scenario): void {
  const dataset = buildDataset(scenario);
  const width = 1280;
  const height = 800;
  const simulation = d3
    .forceSimulation<PageNode, PageLink>()
    .force(
      "link",
      d3
        .forceLink<PageNode, PageLink>() // This force provides links between nodes
        .distance(30)
        .id((d) => d.id.toString()) // This sets the node id accessor to the specified function. If not specified, will default to the index of a node.
    )
    .force("charge", d3.forceManyBody().strength(-20)) // This adds repulsion (if it's negative) between nodes.
    .force("center", d3.forceCenter(width / 2, height / 2)); // This force attracts nodes to the center of the svg area

  const margin = {
    top: 30,
    right: 80,
    bottom: 30,
    left: 30,
  };
  d3.select(`#${htmlElementId} svg`).remove();
  const svg = d3
    .select(`#${htmlElementId}`)
    .append("svg")
    .attr("viewBox", `0 0 ${width} ${height}`)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  // Initialize the links
  const link = svg
    .append("g")
    .attr("class", "links")
    .selectAll("line")
    .data(dataset.links)
    .enter()
    .append("line")
    .attr("marker-end", "url(#arrowhead)");

  // Initialize the nodes
  const node = svg
    .append("g")
    .attr("class", "nodes")
    .selectAll("circle")
    .data(dataset.nodes)
    .enter()
    .append("circle")
    .attr("r", 20)
    .style("stroke-opacity", 0.3)
    .style("stroke-width", (d) => (d.highlight ? 10 : 1))
    .call(
      d3
        .drag<SVGCircleElement, PageNode>() //sets the event listener for the specified typenames and returns the drag behavior.
        .on("start", dragstarted) //start - after a new pointer becomes active (on mousedown or touchstart).
        .on("drag", dragged) //drag - after an active pointer moves (on mousemove or touchmove).
    );

  //appending little triangles, path object, as arrowhead
  //The <defs> element is used to store graphical objects that will be used at a later time
  //The <marker> element defines the graphic that is to be used for drawing arrowheads or polymarkers on a given <path>, <line>, <polyline> or <polygon> element.
  svg
    .append("defs")
    .append("marker")
    .attr("id", "arrowhead")
    .attr("viewBox", "-0 -5 10 10") //the bound of the SVG viewport for the current SVG fragment. defines a coordinate system 10 wide and 10 high starting on (0,-5)
    .attr("refX", 23) // x coordinate for the reference point of the marker. If circle is bigger, this need to be bigger.
    .attr("refY", 0)
    .attr("orient", "auto")
    .attr("markerWidth", 13)
    .attr("markerHeight", 13)
    .attr("xoverflow", "visible")
    .append("svg:path")
    .attr("d", "M 0,-5 L 10 ,0 L 0,5")
    .attr("fill", "#777")
    .style("stroke", "none");

  // Text to nodes
  const text = svg
    .append("g")
    .attr("class", "text")
    .selectAll("text")
    .data(dataset.nodes)
    .enter()
    .append("text")
    .style("font-size", 9)
    .text((d: PageNode) => d.name);

  //Listen for tick events to render the nodes as they update in your Canvas or SVG.
  simulation
    .nodes(dataset.nodes) //sets the simulation’s nodes to the specified array of objects, initializing their positions and velocities, and then re-initializes any bound forces;
    .on("tick", ticked); //use simulation.on to listen for tick events as the simulation runs.
  // After this, Each node must be an object. The following properties are assigned by the simulation:
  // index - the node’s zero-based index into nodes
  // x - the node’s current x-position
  // y - the node’s current y-position
  // vx - the node’s current x-velocity
  // vy - the node’s current y-velocity
  const force = simulation.force<ForceLink<PageNode, PageLink>>("link");
  if (force !== undefined) {
    force.links(dataset.links); //sets the array of links associated with this force, recomputes the distance and strength parameters for each link, and returns this force.
    // After this, Each link is an object with the following properties:
    // source - the link’s source node;
    // target - the link’s target node;
    // index - the zero-based index into links, assigned by this method
  }

  // This function is run at each iteration of the force algorithm, updating the nodes position (the nodes data array is directly manipulated).
  function ticked() {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

    text
      .attr("x", (d) => d.x - 5) //position of the lower left point of the text
      .attr("y", (d) => d.y + 5); //position of the lower left point of the text
  }

  //When the drag gesture starts, the targeted node is fixed to the pointer
  //The simulation is temporarily “heated” during interaction by setting the target alpha to a non-zero value.
  function dragstarted(d: PageNode) {
    if (!d3.event.active) simulation.alphaTarget(0.3).restart(); //sets the current target alpha to the specified number in the range [0,1].
    d.fy = d.y; //fx - the node’s fixed x-position. Original is null.
    d.fx = d.x; //fy - the node’s fixed y-position. Original is null.
  }

  //When the drag gesture starts, the targeted node is fixed to the pointer
  function dragged(d: PageNode) {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  }
}
