
import { Block } from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";
import StepList from "@/components/form/StepList.vue";
import draggable from "vuedraggable";
import BlockContainer from "@/components/form/BlockContainer.vue";

@Options({
  props: ["blocks", "pageId"],
  components: {
    BlockContainer,
    StepList,
    draggable,
  },
})
export default class BlockList extends Vue {
  blocks!: Block[];
  pageId!: string;

  addElement(): void {
    this.blocks.push({
      type: "IfActionBlock",
      parameters: {
        actions: [],
        conditions: [],
      },
    });
  }

  removeBlock(index: number): void {
    this.blocks.splice(index, 1);
  }
}
