
import { StepParameters } from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";
import StringList from "@/components/form/StringList.vue";

@Options({
  props: ["parameters"],
  components: { StringList },
})
export default class OneOf extends Vue {
  parameters!: StepParameters;

  beforeCreate(): void {
    this.parameters.fieldToCheck ??= "";
    this.parameters.values ??= [];
    this.parameters.description ??= "";
  }
}
