
import { StepParameters } from "@/model/Scenario";
import StringList from "@/components/form/StringList.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["parameters"],
  components: { StringList },
})
export default class RecapTabContainsManySituationsIdentifier extends Vue {
  parameters!: StepParameters;

  beforeCreate(): void {
    this.parameters.keyword ??= "";
    this.parameters.description ??= "";
  }
}
