
import { Options, Vue } from "vue-class-component";
import Main from "@/components/Main.vue";
import Login from "@/components/login/Login.vue";
import { AuthService } from "@/service/auth/AuthService";

@Options({
  components: {
    Login,
    Main,
  },
  inject: { authService: "AuthService" },
})
export default class App extends Vue {
  authService!: AuthService;

  isUserAuthenticated = false;

  created(): void {
    this.getIsUserAuthenticated();
  }

  getIsUserAuthenticated(): void {
    this.isUserAuthenticated = this.authService.isUserAuthenticated();
  }
}
