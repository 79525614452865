import { Store } from "vuex";
import * as mutations from "./mutations";
import * as getters from "./getters";
import { Scenario } from "@/model/Scenario";

export interface State {
  scenarios: { [key: string]: Scenario };
}

export function getStore(): Store<State> {
  return new Store<State>({
    state: {
      scenarios: {},
    },
    mutations,
    getters,
  });
}
