
import { Options, Vue } from "vue-class-component";
import DropitoLogo from "@/components/login/DropitoLogo.vue";
import { AuthService } from "@/service/auth/AuthService";

@Options({
  components: { DropitoLogo },
  inject: { authService: "AuthService" },
})
export default class Main extends Vue {
  private hasErrorUsername = false;
  private hasErrorPassword = false;
  private hasErrorCredentials = false;
  private errorMessage = "";
  private authService!: AuthService;

  username = "";
  password = "";

  async submitForm(submitEvent: Event): Promise<void> {
    submitEvent.stopImmediatePropagation();
    this.checkCredentials();

    if (this.hasErrorsLogin) {
      return;
    }

    try {
      await this.authService.authLogin({
        username: this.username,
        password: this.password,
      });
      this.$emit("loginSubmitted");
    } catch (e) {
      this.hasErrorCredentials = true;
      this.errorMessage = e.message;
    }
  }

  checkCredentials(): void {
    this.resetErrors();

    if (this.username === "") {
      this.hasErrorUsername = true;
    }
    if (this.password === "") {
      this.hasErrorPassword = true;
    }
  }

  resetErrors(): void {
    this.hasErrorPassword = false;
    this.hasErrorUsername = false;
    this.hasErrorCredentials = false;
    this.errorMessage = "";
  }

  get hasErrorsLogin(): boolean {
    return this.hasErrorUsername || this.hasErrorPassword;
  }

  get isButtonDisabled(): boolean {
    return this.password === "" || this.username === "";
  }
}
