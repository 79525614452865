
import { Options, Vue } from "vue-class-component";

@Options({
  props: [
    "allPossibleSelectedElementList",
    "checkedElementList",
    "typeElement",
  ],
})
export default class DropdownStringList extends Vue {
  allPossibleSelectedElementList!: string[];
  checkedElementList!: string[];
  typeElement!: string;
  filter = "";
  isComponentSelectorActive = false;

  $refs!: {
    inputSearchDropdown: HTMLInputElement;
  };

  addElement(element: string): void {
    this.checkedElementList.push(element);
    this.filter = "";
    this.$refs.inputSearchDropdown.focus();
  }

  removeElement(index: number): void {
    this.checkedElementList.splice(index, 1);
  }

  get filteredSelectedElement(): string[] {
    if (this.filter === "") {
      return this.allPossibleSelectedElementList;
    }

    return this.allPossibleSelectedElementList.filter((element) =>
      element.toLowerCase().includes(this.filter.toLowerCase())
    );
  }

  onDropdownOpen(): void {
    this.isComponentSelectorActive = true;
    this.$refs.inputSearchDropdown.focus();
  }

  onDropdownClose(): void {
    this.isComponentSelectorActive = false;
    this.filter = "";
  }
}
