
import { Options, Vue } from "vue-class-component";
import { Scenario } from "@/model/Scenario";
import { DropitoAPIService } from "@/service/dropito-api/DropitoAPIService";
import Modal from "@/components/shared/Modal.vue";

@Options({
  inject: { dropitoAPIService: "DropitoAPIService" },
  components: {
    Modal,
  },
})
export default class CreateScenarioModal extends Vue {
  errorNewScenarioId = "";
  errorNewScenarioName = "";
  isSavingScenario = false;
  errorMessage = "";
  scenarioId = "";
  scenarioName = "";

  isModalVisible = false;
  dropitoAPIService!: DropitoAPIService;

  $refs!: {
    modalCreateScenario: HTMLElement;
  };

  openModal(): void {
    this.isModalVisible = true;
    this.resetMessages();
  }

  closeModal(): void {
    this.isModalVisible = false;
    this.resetMessages();
  }

  resetMessages(): void {
    this.scenarioId = "";
    this.scenarioName = "";
    this.resetError();
  }

  resetError(): void {
    this.errorNewScenarioId = "";
    this.errorNewScenarioName = "";
    this.errorMessage = "";
  }

  async addScenario(): Promise<void> {
    this.resetError();

    if (this.scenarioId.length === 0) {
      this.errorNewScenarioId = "Le scenario id ne peut pas être vide";
      return;
    }

    if (this.scenarioName.length === 0) {
      this.errorNewScenarioName = "Le nom du scenario ne peut pas être vide";
      return;
    }

    this.isSavingScenario = true;
    try {
      const scenario = this.initializeScenario();
      await this.dropitoAPIService.createScenario(scenario);
      this.$store.commit("addScenario", scenario);
      this.closeModal();
    } catch (e) {
      this.errorMessage = e.message;
    } finally {
      this.isSavingScenario = false;
    }
  }

  mounted(): void {
    document
      .querySelectorAll(
        ".modal-background, .modal-card-head .delete, .modal-card-foot .button.cancel"
      )
      .forEach((close_element_modal) => {
        close_element_modal.addEventListener("click", () => {
          this.closeModal();
        });
      });
  }

  initializeScenario(): Scenario {
    return {
      id: this.scenarioId,
      name: this.scenarioName,
      pages: [
        {
          id: "first-page",
          label: "first page",
          nextPagesIds: [],
          blocks: [],
          conditions: [],
          identifiers: [],
          displayedData: [],
        },
      ],
      firstPageId: "first-page",
    };
  }

  get hasAPIError(): boolean {
    return this.errorMessage !== "";
  }
}
