import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Main = _resolveComponent("Main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isUserAuthenticated)
      ? (_openBlock(), _createBlock(_component_Login, {
          key: 0,
          onLoginSubmitted: _ctx.getIsUserAuthenticated
        }, null, 8, ["onLoginSubmitted"]))
      : _createCommentVNode("", true),
    (_ctx.isUserAuthenticated)
      ? (_openBlock(), _createBlock(_component_Main, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}