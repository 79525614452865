
import { Options, Vue } from "vue-class-component";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-dark.css";
// Prevent conflicts between bulma and prismjs
import "prismjs/plugins/custom-class/prism-custom-class";
Prism.plugins.customClass.map({ number: "prism-number", tag: "prism-tag" });

@Options({
  props: ["initCode"],
  components: {
    PrismEditor,
  },
  watch: {
    initCode: [{ handler: "onCodeUpdateFromParent", deep: true }],
  },
})
export default class CodeEditor extends Vue {
  initCode!: string;
  private code = "";

  mounted(): void {
    this.code = this.initCode;
  }

  notifyChange(): void {
    this.$emit("changed", this.code);
  }

  highlighter(code: string): string {
    return Prism.highlight(code, Prism.languages.json, "json");
  }

  onCodeUpdateFromParent(newVal: string): void {
    this.code = newVal;
  }
}
