import { State } from "@/store/index";
import { Scenario } from "@/model/Scenario";

export interface NextPageIdChanger {
  scenarioId: string;
  oldNextPageId: string;
  newNextPageId: string;
}

export interface DeletedPageId {
  scenarioId: string;
  pageIdToDelete: string;
}

export const addScenarios = (state: State, scenarios: Scenario[]): void => {
  scenarios.forEach((scenario: Scenario) => {
    addScenario(state, scenario);
  });
};

export const addScenario = (state: State, scenario: Scenario): void => {
  state.scenarios[scenario.id] = scenario;
};

export const overwriteExistingScenario = (
  state: State,
  scenario: Scenario
): void => {
  if (state.scenarios[scenario.id] === undefined) {
    throw new Error("L'id du scénario n'existe pas dans l'API");
  }
  state.scenarios[scenario.id] = scenario;
};

export const changeNextPageId = (
  state: State,
  changeNextPageId: NextPageIdChanger
): void => {
  state.scenarios[changeNextPageId.scenarioId].pages.forEach((page) => {
    page.nextPagesIds = page.nextPagesIds.map((nextPageId) => {
      if (nextPageId === changeNextPageId.oldNextPageId) {
        return changeNextPageId.newNextPageId;
      }
      return nextPageId;
    });
  });
};

export const deleteNextPageId = (
  state: State,
  deletedPageId: DeletedPageId
): void => {
  state.scenarios[deletedPageId.scenarioId].pages.forEach((page) => {
    page.nextPagesIds = page.nextPagesIds.filter(
      (nextPageId) => nextPageId !== deletedPageId.pageIdToDelete
    );
  });
};
