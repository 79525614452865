
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["list"],
})
export default class StringList extends Vue {
  list!: string[];

  // It's a bad practice to declare $refs like this. But I don't have any other ideas
  // because all $refs are dynamic
  $refs!: { [key: string]: HTMLInputElement };

  addElement(): void {
    this.list.push("");

    setTimeout(() => {
      this.$refs[`inputText${this.list.length - 1}`].focus();
    }, 50);
  }

  updateElement(target: HTMLInputElement, index: number): void {
    this.list[index] = target.value;
  }

  removeElement(index: number): void {
    this.list.splice(index, 1);
  }
}
