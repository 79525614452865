export type Scenario = {
  id: string;
  name: string;
  firstPageId: string;
  pages: Page[];
};

export type Page = {
  id: string;
  label: string;
  displayedData: string[];
  nextPagesIds: string[];
  identifiers: Step[];
  conditions: Step[];
  blocks: Block[];
};

export type Selector = {
  type: string;
  path: string;
};

export type Step = {
  type: string;
  parameters: StepParameters;
  forceEditMode?: boolean;
};

export type Block = {
  type: string;
  parameters: BlockParameters;
};

export type RichValueType = {
  type: string;
  value: string;
};

export const EqualsStepParameters = {
  TEXT: { type: "text", value: "Texte" },
  NUMBER: { type: "number", value: "Nombre" },
  RADIO: { type: "radio", value: "Vrai/Faux" },
};

export type StepParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | undefined
    | boolean
    | Selector
    | { value: string }[];
};

export type BlockParameters = {
  conditions?: Step[];
  actions: Step[];
};

export type StepTypeOption = {
  type: string;
  label: string;
  iconClass: string;
  description: string;
};

export function getAvailableSteps(listType: string): StepTypeOption[] {
  switch (listType) {
    case "identifiers":
      return [
        {
          type: "EmptyBrowserIdentifier",
          label: "EmptyBrowser",
          iconClass: "far fa-file",
          description: "Identifier que le navigateur n'est sur aucune page",
        },
        {
          type: "DomContainsIdentifier",
          label: "DomContains",
          iconClass: "fas fa-code",
          description:
            "Vérifier que la page contient les mots suivants : '< mots clés >'",
        },
        {
          type: "DomNotContainsIdentifier",
          label: "DomNotContains",
          iconClass: "fas fa-code",
          description:
            "Vérifier que la page ne contient pas les mots suivants : '< mots clés >'",
        },
        {
          type: "PageTitleIdentifier",
          label: "PageTitle",
          iconClass: "fas fa-heading",
          description: "Vérifier que la page a le titre suivant : '< titre >'",
        },
        {
          type: "PrefixUrlIdentifier",
          label: "PrefixUrl",
          iconClass: "fas fa-link",
          description:
            "Verifier que l'adresse du site commence par les mots suivants : (exemple: https://caf.com/)",
        },
        {
          type: "UrlIdentifier",
          label: "Url",
          iconClass: "fas fa-link",
          description: "Vérifier l'adresse du site correspond à < url >",
        },
        {
          type: "RecapTabContainsManySituationsIdentifier",
          label: "RecapTabContainsManySituationsIdentifier",
          iconClass: "fab fa-penny-arcade",
          description:
            "Vérifier que le navigateur est sur une page avec plus d'une situation professionnelle de type : '< situations spécifiques >' ",
        },
        {
          type: "RecapTabContainsOneSituationIdentifier",
          label: "RecapTabContainsOneSituationIdentifier",
          iconClass: "fab fa-penny-arcade",
          description:
            "Vérifier que le navigateur est sur une page avec uniquement une situation professionnelle de type : '< situation spécifique >'",
        },
      ];
    case "actions":
      return [
        {
          type: "Click",
          label: "Click",
          iconClass: "fas fa-mouse-pointer",
          description: "Cliquer sur le bouton '< nom du bouton >'",
        },
        {
          type: "Dropdown",
          label: "Dropdown",
          iconClass: "fas fa-list",
          description: "Sélectionner dans la liste '< la donnée >'",
        },
        {
          type: "NeverFailClick",
          label: "NeverFailClick",
          iconClass: "fas fa-mouse-pointer",
          description:
            "Cliquer uniquement si le bouton '< nom du bouton >' existe (ne renvoie pas d'erreurs)",
        },
        {
          type: "UploadFile",
          label: "UploadFile",
          iconClass: "fas fa-upload",
          description: "Uploader les fichiers : '< fichiers >'",
        },
        {
          type: "VisitUrl",
          label: "VisitUrl",
          iconClass: "fas fa-link",
          description: "Aller sur une page avec l'url : < url >",
        },
        {
          type: "TypeText",
          label: "TypeText",
          iconClass: "fas fa-keyboard",
          description: "Taper le texte : '< texte >'",
        },
        {
          type: "TypeIBANPart",
          label: "TypeIBANPart",
          iconClass: "fas fa-university",
          description: "Entrer la partie < numéro (1 à 10) > de l'IBAN",
        },
        {
          type: "WaitMs",
          label: "WaitMs",
          iconClass: "far fa-clock",
          description: "Attendre < X > millisecondes",
        },
        {
          type: "ManualPause",
          label: "ManualPause",
          iconClass: "far fa-pause-circle",
          description:
            "Vérifier les informations suivantes : < informations à vérifier >",
        },
        {
          type: "TypeTextIfNotDisabled",
          label: "TypeTextIfNotDisabled",
          iconClass: "fas fa-keyboard",
          description:
            "Taper le texte '< texte >' uniquement si le champ '< nom du champ>' n'est pas désactivé (ne renvoie pas d'erreurs)",
        },
        {
          type: "TypeTextIfExist",
          label: "TypeTextIfExist",
          iconClass: "fas fa-keyboard",
          description:
            "Taper le texte '< texte >' uniquement si le champ '< nom du champ>' existe (ne renvoie pas d'erreurs)",
        },
      ];
    case "conditions":
      return [
        {
          type: "Equals",
          label: "Equals",
          iconClass: "fas fa-equals",
          description:
            "Vérifier que le champ '< nom du champ >' est égal à : '< valeur >'",
        },
        {
          type: "HasField",
          label: "HasField",
          iconClass: "fas fa-check",
          description: "Vérifier que le champ '< nom du champ >' éxiste",
        },
        {
          type: "HasStringField",
          label: "HasStringField",
          iconClass: "fas fa-check",
          description:
            "Vérifier que le champ '< nom du champ >' est de type : chaîne de characteres",
        },
        {
          type: "OneOf",
          label: "OneOf",
          iconClass: "fas fa-list",
          description:
            "Vérifie que le champ '< nom du champ >' contient la/les valeur(s) suivantes : '< valeur(s) >'",
        },
        {
          type: "NotEquals",
          label: "NotEquals",
          iconClass: "fas fa-not-equal",
          description:
            "Vérifie que le champ '< nom du champ >' n'est pas égal à : '< valeur >'",
        },
        {
          type: "NotHasField",
          label: "NotHasField",
          iconClass: "fas fa-times",
          description: "Vérifie que le champ '< nom du champ >' n'existe pas",
        },
        {
          type: "NotOneOf",
          label: "NotOneOf",
          iconClass: "fas fa-list",
          description:
            "Vérifie que le champ '< nom du champ >' ne sont pas dans la/les valeur(s) suivantes : '< valeur(s) >'",
        },
      ];
    default:
      throw new Error("Unsupported step type");
  }
}
