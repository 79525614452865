
import { StepParameters } from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["parameters"],
})
export default class PrefixUrlIdentifier extends Vue {
  parameters!: StepParameters;

  beforeCreate(): void {
    this.parameters.prefixUrl ??= "";
    this.parameters.description ??= "";
  }
}
