
import { Options, Vue } from "vue-class-component";
import { Scenario } from "@/model/Scenario";
import ScenarioEditor from "@/components/ScenarioEditor.vue";
import ScenarioGraph from "@/components/ScenarioGraph.vue";
import DropitoLogo from "@/components/login/DropitoLogo.vue";
import CreateScenarioModal from "@/components/create-scenario/CreateScenarioModal.vue";
import { DropitoAPIService } from "@/service/dropito-api/DropitoAPIService";

@Options({
  inject: { dropitoAPIService: "DropitoAPIService" },
  components: {
    CreateScenarioModal,
    DropitoLogo,
    ScenarioEditor,
    ScenarioGraph,
  },
  watch: {
    "$store.state.scenarios": [{ handler: "onChangeScenarios", deep: true }],
  },
})
export default class Main extends Vue {
  currentScenario: Scenario | null = null;
  hasAPIError = false;
  isGraphModeEnabled = false;
  APIErrorMessage = "";
  dropitoAPIService!: DropitoAPIService;

  onChangeScenarios(): void {
    if (this.currentScenario) {
      // Need to reload the current scenario when a new one is uploaded
      // to take account data in the uploaded scenario
      this.setCurrentScenario(this.currentScenario.id);
    }
  }

  async mounted(): Promise<void> {
    if (!this.$store.getters.hasScenarios()) {
      try {
        const scenarios = await this.dropitoAPIService.getAllScenarios();
        this.$store.commit("addScenarios", scenarios);
      } catch (e) {
        this.APIErrorMessage = e.message;
        this.hasAPIError = true;
        return;
      }
    }

    if (!this.$store.getters.hasScenarios()) {
      this.APIErrorMessage = "Aucun scénario disponible";
      this.hasAPIError = true;
      return;
    }

    await this.setCurrentScenario(
      this.$store.state.scenarios[Object.keys(this.$store.state.scenarios)[0]]
        .id
    );
  }

  async setCurrentScenario(scenarioId: string): Promise<void> {
    this.currentScenario = this.$store.getters.getScenario(scenarioId);
  }

  isActiveScenario(scenarioId: string): boolean {
    return scenarioId === this.currentScenario?.id;
  }

  toggleGraphMode(): void {
    this.isGraphModeEnabled = !this.isGraphModeEnabled;
  }
}
