
import { Options, Vue } from "vue-class-component";
import EmptyBrowserIdentifier from "@/components/steps/identifiers/EmptyBrowserIdentifier.vue";
import DomContainsIdentifier from "@/components/steps/identifiers/DomContainsIdentifier.vue";
import DomNotContainsIdentifier from "@/components/steps/identifiers/DomNotContainsIdentifier.vue";
import PageTitleIdentifier from "@/components/steps/identifiers/PageTitleIdentifier.vue";
import UrlIdentifier from "@/components/steps/identifiers/UrlIdentifier.vue";
import PrefixUrlIdentifier from "@/components/steps/identifiers/PrefixUrlIdentifier.vue";
import RecapTabContainsManySituationsIdentifier from "@/components/steps/identifiers/RecapTabContainsManySituationsIdentifier.vue";
import RecapTabContainsOneSituationIdentifier from "@/components/steps/identifiers/RecapTabContainsOneSituationIdentifier.vue";
import Equals from "@/components/steps/conditions/Equals.vue";
import HasField from "@/components/steps/conditions/HasField.vue";
import HasStringField from "@/components/steps/conditions/HasStringField.vue";
import NotEquals from "@/components/steps/conditions/NotEquals.vue";
import NotHasField from "@/components/steps/conditions/NotHasField.vue";
import NotOneOf from "@/components/steps/conditions/NotOneOf.vue";
import OneOf from "@/components/steps/conditions/OneOf.vue";
import Click from "@/components/steps/actions/Click.vue";
import Dropdown from "@/components/steps/actions/Dropdown.vue";
import ManualPause from "@/components/steps/actions/ManualPause.vue";
import NeverFailClick from "@/components/steps/actions/NeverFailClick.vue";
import TypeText from "@/components/steps/actions/TypeText.vue";
import TypeIBANPart from "@/components/steps/actions/TypeIBANPart.vue";
import UploadFile from "@/components/steps/actions/UploadFile.vue";
import VisitUrl from "@/components/steps/actions/VisitUrl.vue";
import WaitMs from "@/components/steps/actions/WaitMs.vue";
import { getAvailableSteps, Step } from "@/model/Scenario";
import TypeTextIfNotDisabled from "@/components/steps/actions/TypeTextIfNotDisabled.vue";
import TypeTextIfExist from "@/components/steps/actions/TypeTextIfExist.vue";

@Options({
  props: ["step", "listType"],
  components: {
    EmptyBrowserIdentifier,
    DomContainsIdentifier,
    DomNotContainsIdentifier,
    PageTitleIdentifier,
    UrlIdentifier,
    PrefixUrlIdentifier,
    RecapTabContainsManySituationsIdentifier,
    RecapTabContainsOneSituationIdentifier,
    Equals,
    NotEquals,
    HasField,
    NotHasField,
    NotOneOf,
    OneOf,
    HasStringField,
    Click,
    Dropdown,
    ManualPause,
    NeverFailClick,
    TypeText,
    TypeTextIfNotDisabled,
    TypeTextIfExist,
    TypeIBANPart,
    UploadFile,
    VisitUrl,
    WaitMs,
  },
})
export default class StepContainer extends Vue {
  listType!: string;
  step!: Step;
  editMode = false;
  iconClass = "";

  isAvailableStep(): boolean {
    return (
      getAvailableSteps(this.listType).find(
        (stepTypeOption) => stepTypeOption.type === this.step.type
      ) != null
    );
  }

  remove(): void {
    this.$emit("removeStep");
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  created(): void {
    if (this.isAvailableStep()) {
      const stepTypeOption = getAvailableSteps(this.listType).find(
        (stepTypeOption) => stepTypeOption.type === this.step.type
      );
      if (stepTypeOption) {
        this.iconClass = stepTypeOption.iconClass;
        if (!this.step.parameters.description) {
          this.step.parameters.description = stepTypeOption.description;
        }
      }
    }
    this.editMode = this.step.forceEditMode ?? false;
  }
}
