import { createApp } from "vue";
import App from "./App.vue";
import { getStore } from "@/store";
import { DropitoAPIService } from "@/service/dropito-api/DropitoAPIService";
import { JWTTokenAuthService } from "@/service/auth/JWTTokenAuthService";
import { UserDataService } from "@/service/UserDataService";

const app = createApp(App);
const authService = new JWTTokenAuthService();
const dropitoAPIService = new DropitoAPIService(authService);
const userDataService = new UserDataService(dropitoAPIService);

app.use(getStore());
app.provide("AuthService", authService);
app.provide("DropitoAPIService", dropitoAPIService);
app.provide("UserDataService", userDataService);
app.mount("#app");
