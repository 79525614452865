
import { Options, Vue } from "vue-class-component";
import StringList from "@/components/form/StringList.vue";
import StepList from "@/components/form/StepList.vue";
import BlockList from "@/components/form/BlockList.vue";
import { Page } from "@/model/Scenario";
import PageEditorHeader from "@/components/PageEditorHeader.vue";
import DropdownStringList from "@/components/form/DropdownStringList.vue";

@Options({
  components: {
    DropdownStringList,
    PageEditorHeader,
    StringList,
    StepList,
    BlockList,
  },
  props: ["page", "scenarioId"],
})
export default class PageEditor extends Vue {
  page!: Page;
  scenarioId!: string;
  onEditingPageLabel = false;
  errorNewPageLabel = "";

  editPageLabel(): void {
    this.onEditingPageLabel = true;
  }

  savePageLabel(): void {
    this.errorNewPageLabel = "";
    if (this.page.label === "") {
      this.errorNewPageLabel = "Le label de la page ne peut pas être vide";
      return;
    }
    this.onEditingPageLabel = false;
  }

  copyJSON(): void {
    navigator.clipboard.writeText(this.serializedPage);
  }

  get serializedPage(): string {
    return JSON.stringify(this.page, null, 2);
  }

  get allPossibleNextPageIds(): string[] {
    return this.$store.getters
      .getAllPageIdsOfScenario(this.scenarioId)
      .filter(
        (pageId: string) =>
          !this.page.nextPagesIds.includes(pageId) && pageId !== this.page.id
      );
  }
}
