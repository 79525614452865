import { DataSchema, DataSchemaProperty } from "@/model/DataSchema";
import { UserData } from "@/model/Task";
import { DropitoAPIService } from "@/service/dropito-api/DropitoAPIService";
import { set, get, isNil } from "lodash";

export class UserDataService {
  constructor(readonly dropitoAPIService: DropitoAPIService) {}

  async createUserData(scenarioId: string): Promise<UserData> {
    const displayedData = await this.dropitoAPIService.getUsedData(scenarioId);
    const dataSchema = await this.dropitoAPIService.getDataSchema();
    return UserDataService.buildUserDataSample(displayedData, dataSchema);
  }

  private static buildUserDataSample(
    displayedData: string[],
    dataSchema: DataSchema
  ): UserData {
    const userDataSample: UserData = {};
    for (const property of displayedData) {
      const dataSchemaPath = this.buildDataSchemaPath(property, dataSchema);
      set(
        userDataSample,
        property,
        UserDataService.fakeValue(dataSchemaPath, dataSchema)
      );
    }
    return userDataSample;
  }

  /**
   * From a property string, build the full dataSchema path from which we will retrieve the type
   */
  private static buildDataSchemaPath(property: string, dataSchema: DataSchema) {
    let path = "";
    let hasRef = false;

    const splittedProperty = property.split(".");
    for (let i = 0; i < splittedProperty.length; i++) {
      path += splittedProperty[i];
      const dataSchemaNode = get(dataSchema.properties, path);
      // If we find a reference to a JSONSchema definition, replace the path with the definition
      if (!isNil(dataSchemaNode) && "$ref" in dataSchemaNode) {
        hasRef = true;
        path = dataSchemaNode.$ref.replace("#/", "").replace(/\//, "."); // Transform JSONSchema pointer to JS Object notation
      }

      // Do not add the .properties suffix on the last element
      if (i < splittedProperty.length - 1) {
        path += ".properties.";
      }
    }
    // If the dataSchema property does not link to a definition, it will be placed under the 'properties' root key, otherwise under the '$defs' root key
    if (!hasRef) {
      path = `properties.${path}`;
    }
    return path;
  }

  /**
   * Generate a dummy value using the same type the property is defined in the dataschema
   * Examples: [properties.etatCivil.properties.nom: string => ""] [properties.isEnceinte: boolean => false]
   * @param property the full path to the property (e.g: etatCivil.properties.nom)
   * @param dataSchema the full dropito data-schema
   * @returns a dummy value of the same type as the property definition (fallback to a string if the property does not exist in the dataSchema)
   */
  private static fakeValue(propertyPath: string, dataSchema: DataSchema) {
    const schemaNode: DataSchemaProperty = get(dataSchema, propertyPath);
    if (isNil(schemaNode)) return "";
    if ("$ref" in schemaNode) {
      throw new Error(
        "Logic error: $ref found in dataSchema while path should have been replaced"
      );
    }
    if ("enum" in schemaNode) {
      return schemaNode.enum[0];
    }
    switch (schemaNode.type) {
      case "boolean":
        return false;
      case "number":
        return 0;
      case "string":
      default:
        return "";
    }
  }
}
