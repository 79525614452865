export abstract class AuthError extends Error {}

export class NoTokenAuthError extends AuthError {
  constructor() {
    super(`Current user has no Authentication Token, please log in`);
  }
}

export class RefreshTokenAuthError extends AuthError {
  constructor(refreshToken: string, message: string) {
    super(
      `Could not refresh token user with token ${refreshToken}: ${message}`
    );
  }
}

export class MalformedJWTAuthError extends AuthError {
  constructor(message: string) {
    super(`JWT Malformed: ${message}`);
  }
}
