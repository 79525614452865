
import { Selector } from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["selector"],
})
export default class SelectorForm extends Vue {
  selector!: Selector;
}
