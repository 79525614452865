
import { Options, Vue } from "vue-class-component";
import StepList from "@/components/form/StepList.vue";
import draggable from "vuedraggable";
import { Block } from "@/model/Scenario";

@Options({
  props: ["pageId", "block"],
  components: {
    StepList,
    draggable,
  },
})
export default class BlockContainer extends Vue {
  pageId!: string;
  block!: Block;
}
