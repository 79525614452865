
import { getAvailableSteps, Step, StepTypeOption } from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";
import StepContainer from "@/components/steps/StepContainer.vue";
import draggable from "vuedraggable";

let componentId = 0;

@Options({
  props: ["steps", "listType", "pageId"],
  components: {
    StepContainer,
    draggable,
  },
})
export default class StepList extends Vue {
  steps!: Step[];
  pageId!: string;
  listType!: "identifiers" | "actions" | "conditions";
  componentSelectorId = "";
  isComponentSelectorActive = false;

  beforeCreate(): void {
    // Generate a unique id for each component
    this.componentSelectorId = `stepType-${componentId}`;
    componentId += 1;
  }

  addElement(elementType: string): void {
    this.steps.push({ type: elementType, parameters: {}, forceEditMode: true });
  }

  availableSteps(): StepTypeOption[] {
    return getAvailableSteps(this.listType);
  }

  removeElement(index: number): void {
    this.steps.splice(index, 1);
  }
}
