
import { StepParameters } from "@/model/Scenario";
import { Options, Vue } from "vue-class-component";
import RichValue from "@/components/steps/conditions/RichValue.vue";

@Options({
  props: ["parameters"],
  components: { RichValue },
})
export default class NotEquals extends Vue {
  parameters!: StepParameters;

  beforeCreate(): void {
    this.parameters.fieldToCheck ??= "";
    this.parameters.value ??= "";
    this.parameters.description ??= "";
  }
}
