import { State } from "@/store/index";
import { Scenario } from "@/model/Scenario";

export const isScenarioExist =
  (state: State) =>
  (scenarioId: string): boolean => {
    return state.scenarios[scenarioId] !== undefined;
  };

export const getScenario =
  (state: State) =>
  (scenarioId: string): Scenario => {
    return state.scenarios[scenarioId];
  };

export const getAllPageIdsOfScenario =
  (state: State) =>
  (scenarioId: string): string[] => {
    return getScenario(state)(scenarioId).pages.map((page) => page.id);
  };

export const hasScenarios = (state: State) => (): boolean => {
  return Object.keys(state.scenarios).length > 0;
};

export const isPageIdAlreadyExist =
  (state: State) =>
  (newPageId: string, scenarioId: string): boolean => {
    return (
      getScenario(state)(scenarioId).pages.find(
        (page) => page.id === newPageId
      ) !== undefined
    );
  };
